jQuery(document).ready(function($){
    // Set placeholders on login screen
    if($('.login.wp-core-ui').length){
        $('#user_login').attr('placeholder', 'Gebruikersnaam of e-mailadres');
        $('#user_pass').attr('placeholder', 'Wachtwoord')
    }

    // Make all product descriptions same height
    if($('.page__product').length && $(document).width() > 768) {
        var height = 0;
        $('.product__content').each(function(){
            if($(this).outerHeight() > height) height = $(this).outerHeight();
        });
        $('.product__content').css('height', height);
    }

    // Make sure footer is at bottom of screen if page is smaller than screen height
    $('main').css('min-height', $(window).height() - $('header').outerHeight() - $('footer').outerHeight());

    // Animate
    animate($);

    // Layout logos
    layoutLogos(jQuery);
    jQuery('.page__logo').on('load', function(){
        layoutLogos(jQuery);
    });

    // Menu
    $('.nav__open, .nav__close').on('click', function(){
        $(this).closest('.nav').toggleClass('open');
    });

    if($('.nav__list-wrapper').outerHeight() > $(window).height() - 100) {
        $('.nav').addClass('scroll');
    }

    $('.page__menu-toggle').on('click', function(){
        $('.page__menu').toggleClass('open')
    });

    // Product images
    $('.product__thumb').on('click', function(){
        $(this).closest('.product__images').find('.product__image').attr('src', $(this).attr('src'));
    })
});

jQuery(document).on('scroll', function() {
    animate(jQuery);
});

function animate($){
    if($(document).height() - 40 < $(window).height() + $(document).scrollTop()) {
        // Animate footer
        $('.footer__logo-wrapper').addClass('animate');
    }
}

function layoutLogos($) {
    var logos = $('.page__logo');
    logos.css('height', 'auto');

    var totalSize = 0;
    logos.each(function(){
        totalSize += $(this).outerWidth() / $(this).height();
    });
    logos.css('height', $('.page__logos').width() / totalSize);
}
